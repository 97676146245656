<template>
  <div>
    <h5>Автомобиль</h5>
    <div>{{ car.mark }} {{ car.model }} {{ car.number }}</div>
    <h5 class="mt-2">Водитель</h5>
    <div class="autocomplete-field form-group">
      <VueAutosuggest
        ref="driver-input"
        :value="suggestionValue"
        :suggestions="suggestionsList"
        :input-props="{
          placeholder: 'Водитель',
          class: 'form-control',
          autofocus: 'true',
          id: 'driver-input',
        }"
        component-attr-class-autosuggest-results-container="autocomplete-field-dropdown"
        :get-suggestion-value="displayDiver"
        @input="onInputHandler"
        @selected="onSelectedHandler"
        @hook:mounted="onMountedSuggest"
      >
        <template v-slot="{suggestion}">
          <span>
            {{ suggestion.item.last_name }}
            {{ suggestion.item.first_name }}
            {{ suggestion.item.middle_name }}
            {{ suggestion.item.email }}
          </span>
        </template>
      </VueAutosuggest>
    </div>
    <b-button-group>
      <b-button
        variant="success"
        :disabled="!updated && !!driverSelected"
        @click="onSaveButtonHandler"
      >
        Сохранить
      </b-button>
      <b-button
        variant="primary"
        @click="onRepairButtonHandler"
      >
        Ремонт
      </b-button>
      <b-button
        v-if="plan !== null"
        variant="danger"
        @click="onDeleteButtonHandler"
      >
        Удалить
      </b-button>
      <b-button
        variant="light"
        @click="$emit('cancel')"
      >
        Отмена
      </b-button>
    </b-button-group>
  </div>
</template>

<script>
import {
  BButtonGroup, BButton,
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'

export default {
  components: {
    VueAutosuggest,
    BButtonGroup,
    BButton,
  },
  props: {
    plan: {
      type: Object,
      default: null,
    },
    car: {
      type: Object,
      required: true,
    },
    // eslint-disable-next-line vue/prop-name-casing
    is_day: {
      type: Boolean,
      default: true,
    },
    date: {
      type: String,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      updated: false,
      loading: false,
      inputHandlerTimeout: null,
      driversList: [],
      driversListLoad: false,
      driverSelected: null,
    }
  },
  computed: {
    suggestionsList() {
      return [{
        data: this.driversList,
      }]
    },
    suggestionValue() {
      if (this.driverSelected === null) return ''
      return `${this.driverSelected.first_name} ${this.driverSelected.last_name} ${this.driverSelected.middle_name} ${this.driverSelected.email}`
    },
  },
  mounted() {
    this.onUpdatePlan()
  },
  methods: {
    onMountedSuggest() {
      setTimeout(() => {
        const field = this.$refs['driver-input'].$el.querySelector('input')
        field.focus()
        if (this.suggestionValue) {
          field.setSelectionRange(0, this.suggestionValue.length)
        }
      }, 1)
    },
    searchDrivers(data) {
      if (this.loading) {
        return
      }
      this.loading = true
      this.$axios.get(`/drivers?search=${data}&page_size=10&filter[has_car_contract]=1`)
        .then(response => {
          this.driversList = response.data.data
        }).catch().finally(() => {
          this.loading = false
        })
    },
    savePlan(driverId) {
      const params = {
        car_id: this.car.id,
        date: this.date,
        is_day: this.is_day,
        driver_id: driverId,
      }
      if (this.plan) {
        this.$axios.put(`/cars/plans/${this.plan.id}`, params).then(response => {
          this.$emit('saved', response.data.data)
        })
      } else {
        this.$axios.post('/cars/plans', params).then(response => {
          this.$emit('saved', response.data.data)
        })
      }
    },
    onInputHandler(data) {
      this.driversList = []
      if (data.length <= 3) return
      clearTimeout(this.inputHandlerTimeout)
      this.inputHandlerTimeout = setTimeout(
        () => {
          this.searchDrivers(data)
        },
        300,
      )
    },
    onUpdatePlan() {
      if (this.plan) {
        this.driverSelected = this.plan.driver
      }
    },
    onSelectedHandler(suggest) {
      this.driverSelected = suggest.item
      this.driversList = []
      this.updated = true
    },
    onSaveButtonHandler() {
      this.savePlan(this.driverSelected.id)
    },
    onDeleteButtonHandler() {
      this.$axios.delete(`/cars/plans/${this.plan.id}`).then(response => {
        if (response.data.status === 'success') {
          this.$emit('deleted')
        }
      })
    },
    onRepairButtonHandler() {
      this.savePlan(null)
    },
    displayDiver(suggest) {
      return `${suggest.item.first_name} ${suggest.item.last_name} ${suggest.item.middle_name} ${suggest.item.email}`
    },
  },
}
</script>
